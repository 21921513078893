import { plusOrMinus } from '@paid-ui/constants';
import { PriceVariationMethod, VariationStage } from '@paid-ui/enums/variation';
import { WorkItemStatus } from '@paid-ui/enums/work-item';
import { type Adjustment, type Variation } from '@paid-ui/types';
import { sumBy } from 'lodash';

import { contractManager } from '../model';
import { type Contract } from '../types';

const approvalSet = new Set([VariationStage.APPROVED, VariationStage.COMPLETED]);

const unapprovalSet = new Set([
  VariationStage.SUBMITTED,
  VariationStage.REJECTED,
  VariationStage.RESUBMITTED,
]);

const getVariationPrice = (v: Variation) =>
  Number(v.totalVariationPrice) * plusOrMinus[v.totalPriceImpact ?? PriceVariationMethod.NONE];

const getAdjustmentPrice = (v: Adjustment) => {
  return sumBy(
    v.items,
    (item) =>
      Number(item.netAdjustment) *
      plusOrMinus[item.netAdjustmentImpact ?? PriceVariationMethod.NONE],
  );
};

export const saveContractBreakdown = (contract?: Contract) => {
  if (!contract) return;
  const {
    price,
    gstSettings,
    variations = [],
    adjustments = [],
    variationWorkItems = [],
    paymentSchedule,
  } = contract;

  const gstFree = gstSettings?.gstFree ?? true;
  const gstRate = gstSettings?.gstRate ?? 0;
  const priceIncludeGst = gstSettings?.priceIncludeGst ?? false;

  const isPeriodic = Boolean(paymentSchedule);

  const inclGst = (amount?: number) => {
    if (gstFree) return amount ?? 0;
    if (priceIncludeGst) return amount ?? 0;
    return Math.round((amount ?? 0) * (100 + gstRate)) / 100;
  };

  const exclGst = (amount?: number) => {
    if (gstFree) return amount ?? 0;
    if (!priceIncludeGst) return amount ?? 0;
    return Math.round(((amount ?? 0) * 10_000) / (100 + gstRate)) / 100;
  };

  const initialPrice = price?.initial ?? 0;
  const currentPrice = price?.current ?? 0;
  const paid = price?.paid;
  const remaining = price?.remaining;

  contractManager.breakdown.initial = {
    value: initialPrice,
    exclGst: exclGst(initialPrice),
    inclGst: inclGst(initialPrice),
  };

  contractManager.breakdown.current = {
    value: currentPrice,
    exclGst: exclGst(currentPrice),
    inclGst: inclGst(currentPrice),
  };

  contractManager.breakdown.paid = paid ?? { amount: 0, percentage: 0 };
  contractManager.breakdown.remaining = remaining ?? { amount: currentPrice, percentage: 100 };

  const provisionalVariations = variations.filter(
    ({ variationStage }) => variationStage !== VariationStage.ABANDONED,
  );

  const unapprovalVariations = variations.filter(
    ({ variationStage }) => variationStage && unapprovalSet.has(variationStage),
  );

  const unapprovalVariationWorkItems = variationWorkItems.filter(({ workItemStatus }) =>
    [WorkItemStatus.SUBMITTED, WorkItemStatus.REJECTED, WorkItemStatus.RESUBMITTED].includes(
      workItemStatus,
    ),
  );

  const unapprovalAdjustments = adjustments.filter(
    ({ adjustmentStage }) => adjustmentStage && unapprovalSet.has(adjustmentStage),
  );

  const provisionalAdjustments = adjustments.filter(
    ({ adjustmentStage }) => adjustmentStage !== VariationStage.ABANDONED,
  );

  const approvedVariations = variations.filter(
    ({ variationStage }) => variationStage && approvalSet.has(variationStage),
  );

  const approvedVariationWorkItems = variationWorkItems.filter(({ workItemStatus }) =>
    [WorkItemStatus.APPROVED, WorkItemStatus.COMPLETED].includes(workItemStatus),
  );

  const provisionalVariationWorkItems = variationWorkItems.filter(
    ({ workItemStatus }) => workItemStatus !== WorkItemStatus.ABANDONED,
  );

  const approvedAdjustments = adjustments.filter(
    ({ adjustmentStage }) => adjustmentStage && approvalSet.has(adjustmentStage),
  );

  const totalVariationsPrice = isPeriodic
    ? sumBy(variationWorkItems, (item) => item.amount)
    : sumBy(variations, getVariationPrice) + sumBy(adjustments, getAdjustmentPrice);

  const provisionalVariationsPrice = isPeriodic
    ? sumBy(provisionalVariationWorkItems, (item) => item.amount)
    : sumBy(provisionalVariations, getVariationPrice) +
      sumBy(provisionalAdjustments, getAdjustmentPrice);

  const approvedVariationsPrice = isPeriodic
    ? sumBy(approvedVariationWorkItems, (item) => item.amount)
    : sumBy(approvedVariations, getVariationPrice) + sumBy(approvedAdjustments, getAdjustmentPrice);

  const unapprovedVariationsPrice = isPeriodic
    ? sumBy(unapprovalVariationWorkItems, (item) => item.amount)
    : sumBy(unapprovalVariations, getVariationPrice) +
      sumBy(unapprovalAdjustments, getAdjustmentPrice);

  const provisionalContractPrice = initialPrice + totalVariationsPrice;

  contractManager.breakdown.variations = {
    total: {
      value: totalVariationsPrice,
      exclGst: exclGst(totalVariationsPrice),
      inclGst: inclGst(totalVariationsPrice),
    },
    provisional: {
      value: provisionalVariationsPrice,
      exclGst: exclGst(provisionalVariationsPrice),
      inclGst: inclGst(provisionalVariationsPrice),
    },
    approved: {
      value: approvedVariationsPrice,
      exclGst: exclGst(approvedVariationsPrice),
      inclGst: inclGst(approvedVariationsPrice),
    },
    unapproved: {
      value: unapprovedVariationsPrice,
      exclGst: exclGst(unapprovedVariationsPrice),
      inclGst: inclGst(unapprovedVariationsPrice),
    },
  };

  contractManager.breakdown.provisional = {
    value: provisionalContractPrice,
    exclGst: exclGst(provisionalContractPrice),
    inclGst: inclGst(provisionalContractPrice),
  };
};
