import { contractManager } from '../model';
import { type Contract } from '../types';

export const saveGstSettings = (contract?: Contract) => {
  if (!contract) return;
  const { gstSettings, paymentSchedule } = contract;
  contractManager.gstFree = gstSettings?.gstFree ?? false;
  contractManager.gstRate = gstSettings?.gstRate ?? 10;
  contractManager.priceIncludeGst = gstSettings?.priceIncludeGst ?? !paymentSchedule;
};
