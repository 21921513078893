export const HelmetIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M5.66852 12.7092H1.51404C1.35218 12.7092 1.24427 12.6013 1.24427 12.4394V10.9287C1.24427 9.57982 1.59497 8.25794 2.2694 7.09792C3.15965 5.53325 4.58944 4.31927 6.26202 3.6988C6.39691 3.64485 6.55877 3.72578 6.61272 3.86066C6.66668 3.99555 6.58575 4.15741 6.45086 4.21137C4.88618 4.80486 3.5643 5.91093 2.72801 7.36769C2.10754 8.44678 1.78381 9.68773 1.78381 10.9557V12.1966H5.66852C5.83039 12.1966 5.93829 12.3045 5.93829 12.4664C5.93829 12.6282 5.80341 12.7092 5.66852 12.7092Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1334 3.36273C6.47434 3.22974 6.82907 3.43314 6.94661 3.72697C7.0836 4.06946 6.87965 4.42712 6.58436 4.54523L6.57836 4.54763C5.08929 5.11245 3.83359 6.16407 3.03987 7.54667C2.45178 8.56944 2.14342 9.74848 2.14342 10.9556V11.8368H5.66844C5.83111 11.8368 5.99462 11.8926 6.11836 12.0163C6.24211 12.1401 6.2979 12.3036 6.2979 12.4663C6.2979 12.6588 6.21266 12.822 6.0786 12.9292C5.9537 13.0292 5.80294 13.0688 5.66844 13.0688H1.51395C1.35128 13.0688 1.18777 13.013 1.06403 12.8892C0.940279 12.7655 0.884486 12.602 0.884486 12.4393V10.9286C0.884486 9.51797 1.25112 8.13408 1.9575 6.91849C2.88889 5.28232 4.38324 4.01292 6.1334 3.36273Z"
      />
      <path d="M16.4593 12.7092H12.3048C12.143 12.7092 12.0351 12.6012 12.0351 12.4394C12.0351 12.2775 12.143 12.1696 12.3048 12.1696H16.1895V10.9287C16.1895 7.9342 14.3011 5.23648 11.5225 4.18437C11.3876 4.13042 11.3067 3.96855 11.3606 3.83367C11.4146 3.69878 11.5764 3.61785 11.7113 3.6718C14.7058 4.80484 16.7291 7.71838 16.7291 10.9287V12.4394C16.7291 12.5743 16.5942 12.7092 16.4593 12.7092Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8411 3.33619C11.4997 3.20218 11.1443 3.40571 11.0266 3.69993C10.8896 4.04242 11.0935 4.40007 11.3888 4.51819L11.395 4.52061C14.0352 5.52028 15.8298 8.08499 15.8298 10.9285V11.8098H12.3047C12.1421 11.8098 11.9786 11.8656 11.8548 11.9893C11.7311 12.113 11.6753 12.2766 11.6753 12.4392C11.6753 12.6019 11.7311 12.7654 11.8548 12.8892C11.9786 13.0129 12.1421 13.0687 12.3047 13.0687H16.4592C16.6437 13.0687 16.798 12.9801 16.899 12.879C17.0001 12.778 17.0887 12.6237 17.0887 12.4392V10.9285C17.0887 7.569 14.9735 4.52264 11.8411 3.33619Z"
      />
      <path d="M12.3048 12.7092L5.66852 12.7092C5.50666 12.7092 5.39877 12.6014 5.39877 12.4395C5.39877 12.2776 5.50667 12.1697 5.66854 12.1697C6.26204 12.1697 6.74762 11.6841 6.74762 11.0907L7.23321 3.26727C7.26019 2.70075 7.74578 2.26912 8.3123 2.26912H9.68813C10.2547 2.26912 10.7402 2.70075 10.7672 3.26727L11.2528 11.0907C11.2528 11.6841 11.7384 12.1697 12.3319 12.1697C12.4938 12.1697 12.6017 12.2776 12.6017 12.4395C12.6017 12.6014 12.4667 12.7092 12.3048 12.7092ZM6.85553 12.1697H11.0909C10.8482 11.873 10.6863 11.5223 10.6863 11.0907L10.2007 3.29425C10.1737 3.02448 9.95791 2.78169 9.66116 2.78169H8.28532C7.98857 2.78169 7.77276 2.9975 7.74578 3.29425L7.26019 11.1176C7.26019 11.5223 7.1253 11.873 6.85553 12.1697Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4621 11.81C10.3781 11.5971 10.3283 11.3621 10.3268 11.1024L9.8424 3.32493C9.83608 3.27384 9.81202 3.22523 9.77793 3.19115C9.74519 3.15841 9.70644 3.14138 9.66134 3.14138H8.2855C8.19318 3.14138 8.11761 3.19629 8.10459 3.32257L7.62003 11.1293C7.61874 11.3704 7.57726 11.598 7.49616 11.81H10.4621ZM6.87424 3.24738C6.91245 2.4766 7.56907 1.90942 8.31248 1.90942H9.68831C10.4317 1.90942 11.0883 2.4766 11.1266 3.24738L11.6127 11.0795V11.0907C11.6127 11.4855 11.9372 11.81 12.3321 11.81C12.4947 11.81 12.6583 11.8658 12.782 11.9896C12.9057 12.1133 12.9615 12.2768 12.9615 12.4395C12.9615 12.8436 12.6186 13.0687 12.3047 13.0687L5.66844 13.0688C5.50576 13.0688 5.34254 13.0132 5.21879 12.8894C5.09504 12.7657 5.03925 12.6022 5.03925 12.4395C5.03925 12.2768 5.09504 12.1133 5.21879 11.9896C5.34254 11.8658 5.50604 11.81 5.66872 11.81C6.06356 11.81 6.38811 11.4855 6.38811 11.0907V11.0795L6.87424 3.24738Z"
      />
      <path d="M13.3297 15.7307H4.64306C4.21143 15.7307 3.83375 15.461 3.67188 15.0563C3.61793 14.9484 3.537 14.8135 3.45607 14.7326C3.26723 14.5437 2.99745 14.4358 2.72768 14.4358H1.4058C1.0551 14.4358 0.731372 14.274 0.542532 13.9772C0.353692 13.6805 0.299738 13.3298 0.434623 13.0061L0.515555 12.8442C0.677418 12.4395 1.0551 12.1698 1.48673 12.1698H16.54C16.9716 12.1698 17.3493 12.4395 17.5112 12.8442L17.5651 13.0061C17.7 13.3298 17.6461 13.6805 17.4572 13.9772C17.2684 14.247 16.9446 14.4358 16.5939 14.4358H15.2721C14.8404 14.4358 14.4627 14.6786 14.3009 15.0833C14.139 15.461 13.7613 15.7307 13.3297 15.7307ZM1.45976 12.7093C1.24394 12.7093 1.08208 12.8442 1.00114 13.033L0.920212 13.1949C0.866258 13.3568 0.866258 13.5186 0.974167 13.6535C1.0551 13.7884 1.21696 13.8693 1.37882 13.8693H2.7007C3.13234 13.8693 3.51002 14.0312 3.80677 14.3279C3.96863 14.4898 4.07654 14.6517 4.15747 14.8405C4.2384 15.0563 4.42724 15.1912 4.61608 15.1912H13.3297C13.5455 15.1912 13.7074 15.0563 13.7883 14.8405C14.0311 14.247 14.5976 13.8693 15.2451 13.8693H16.567C16.7288 13.8693 16.8907 13.7884 16.9716 13.6535C17.0795 13.5186 17.0795 13.3568 17.0256 13.1949L16.9446 13.033C16.8637 12.8442 16.6749 12.7093 16.486 12.7093H1.45976Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.187733 12.6959C0.404198 12.1712 0.904688 11.8102 1.48686 11.8102H16.5401C17.1278 11.8102 17.6322 12.178 17.8453 12.7107L17.8492 12.7205L17.9026 12.8809C18.083 13.3265 17.9996 13.7951 17.7608 14.1705L17.7566 14.1771L17.752 14.1836C17.502 14.5408 17.0724 14.7957 16.5941 14.7957H15.2722C14.9808 14.7957 14.7397 14.9552 14.635 15.217L14.6317 15.2251C14.4164 15.7275 13.9134 16.0906 13.3298 16.0906H4.64319C4.06129 16.0906 3.561 15.7299 3.34437 15.2056C3.29628 15.1115 3.23932 15.0245 3.20185 14.9871C3.08321 14.8684 2.90614 14.7957 2.72781 14.7957H1.40593C0.943596 14.7957 0.499935 14.5802 0.239198 14.1705C-0.00198229 13.7915 -0.0845812 13.3174 0.102724 12.8678L0.107489 12.8564L0.187733 12.6959ZM1.39379 13.0897C1.37436 13.1041 1.35074 13.1308 1.33188 13.1748L1.32772 13.1846L1.25513 13.3297C1.24491 13.3663 1.24382 13.3901 1.24496 13.4033C1.24591 13.4142 1.2481 13.4201 1.25517 13.4289L1.27028 13.4478L1.28273 13.4686C1.28966 13.4801 1.32491 13.5097 1.37895 13.5097H2.70083C3.22961 13.5097 3.69828 13.7107 4.06124 14.0737C4.25475 14.2672 4.38886 14.4671 4.48821 14.6989L4.49148 14.7066L4.49439 14.7143C4.53056 14.8108 4.59859 14.8316 4.61621 14.8316H13.3298C13.3585 14.8316 13.3746 14.8241 13.388 14.8136C13.4043 14.8009 13.4299 14.7723 13.4517 14.7143L13.4535 14.7093L13.4555 14.7044C13.7543 13.974 14.4536 13.5097 15.2452 13.5097H16.5671C16.6211 13.5097 16.6564 13.4801 16.6633 13.4686L16.6758 13.4478L16.6909 13.4289C16.6979 13.4201 16.7001 13.4142 16.7011 13.4033C16.7022 13.3901 16.7011 13.3663 16.6909 13.3297L16.6183 13.1846L16.6142 13.1748C16.5822 13.1002 16.5124 13.0691 16.4862 13.0691H1.45988C1.4307 13.0691 1.41089 13.0771 1.39379 13.0897Z"
      />
      <path d="M4.39973 11.6303C4.23786 11.6303 4.12995 11.5224 4.12995 11.3606V11.0638C4.12995 10.3624 3.96809 9.661 3.67134 9.04052C3.50948 8.7168 3.32064 8.44703 3.10482 8.17725C3.02389 8.06935 3.02389 7.88051 3.1318 7.79957C3.23971 7.71864 3.42855 7.71864 3.50948 7.82655C3.75227 8.1233 3.96809 8.44703 4.12995 8.79773C4.48066 9.49914 4.64252 10.2815 4.64252 11.0638V11.3606C4.6695 11.4954 4.53461 11.6303 4.39973 11.6303Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79162 7.6032C3.66828 7.44437 3.48819 7.38957 3.34795 7.3829C3.20469 7.37607 3.0454 7.41475 2.91607 7.51175C2.75148 7.6352 2.69507 7.81871 2.68829 7.96113C2.68147 8.10438 2.72015 8.26367 2.81715 8.39301L2.82052 8.3975L2.82403 8.40189C3.02953 8.65877 3.20176 8.90606 3.3483 9.19851C3.62119 9.77021 3.77034 10.4175 3.77034 11.0637V11.3605C3.77034 11.5232 3.82614 11.6867 3.94988 11.8104C4.07363 11.9342 4.23714 11.99 4.39981 11.99C4.583 11.99 4.74141 11.9022 4.84486 11.7914C4.94167 11.6877 5.02464 11.5246 5.0023 11.334V11.0637C5.0023 10.2346 4.8312 9.39788 4.45426 8.64181C4.27804 8.26138 4.04644 7.91524 3.79162 7.6032Z"
      />
      <path d="M13.5729 11.6302C13.4111 11.6302 13.3032 11.5223 13.3032 11.3604V11.0637C13.3032 10.2814 13.492 9.49901 13.8157 8.79761C13.9776 8.47388 14.1934 8.15015 14.4362 7.82643C14.5171 7.71852 14.706 7.69154 14.8139 7.79945C14.9218 7.88038 14.9488 8.06922 14.8409 8.17713C14.6251 8.4469 14.4362 8.74365 14.3013 9.0404C14.0046 9.66088 13.8427 10.3623 13.8427 11.0637V11.3604C13.8427 11.4953 13.7348 11.6302 13.5729 11.6302Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6628 11.3602C13.6628 11.3602 13.6628 11.3603 13.6628 11.3602V11.3602ZM15.0501 7.52754C14.9103 7.399 14.7328 7.35924 14.5808 7.37372C14.426 7.38846 14.2591 7.46282 14.1484 7.61039C13.899 7.94289 13.6693 8.28589 13.494 8.63653L13.4914 8.64155L13.4891 8.64666C13.1456 9.39078 12.9434 10.2243 12.9434 11.0635V11.3602C12.9434 11.5229 12.9992 11.6864 13.123 11.8101C13.2467 11.9339 13.4102 11.9897 13.5729 11.9897C13.9564 11.9897 14.2024 11.67 14.2024 11.3602V11.0635C14.2024 10.4162 14.352 9.76778 14.6258 9.19538L14.6287 9.18903C14.7475 8.92778 14.916 8.66056 15.1123 8.4134C15.241 8.27359 15.2808 8.09598 15.2663 7.94385C15.2523 7.79639 15.1841 7.63791 15.0501 7.52754Z"
      />
    </svg>
  );
};
