import { ClaimMethod, PropertyUsage } from '@paid-ui/enums/claim';
import { ContractRole, ContractType } from '@paid-ui/enums/contract';
import { PartyLinkingStatus, PayType } from '@paid-ui/enums/party';
import { ProjectRole } from '@paid-ui/enums/project';
import { SecurityType, SupercontractBrand } from '@paid-ui/enums/supercontract';

import { BusinessStructureExtended } from '../enums';

export const partyJoinStatusSet = new Set([
  PartyLinkingStatus.JOINED,
  PartyLinkingStatus.READY_TO_SIGN,
  PartyLinkingStatus.SIGNED,
]);

export const defaultContractRoleMap = {
  [PayType.PAYEE]: ContractRole.BUILDER,
  [PayType.PAYER]: ContractRole.PRINCIPAL,
  [PayType.INTERMEDIARY]: ContractRole.ARCHITECT,
};

export const defaultContractNameMap = {
  [ContractType.HEAD_CONTRACT]: 'Building / Head contract',
  [ContractType.SUBCONTRACT]: 'Subcontract',
  [ContractType.SUPPLY_CONTRACT]: 'Supply contract',
};

export const contractTypeMap = {
  [ContractType.HEAD_CONTRACT]: 'Building / Head',
  [ContractType.SUBCONTRACT]: 'Subcontract',
  [ContractType.SUPPLY_CONTRACT]: 'Supply contract',
};

export const claimMethodMap = {
  [ClaimMethod.STAGED]: 'Staged',
  [ClaimMethod.PERIODIC]: 'Periodic',
};

export const propertyUsageMap = {
  [PropertyUsage.COMMERCIAL]: 'Commercial',
  [PropertyUsage.RESIDENTIAL]: 'Residential',
};

export const businessStructureOptions = [
  {
    label: 'Company',
    value: BusinessStructureExtended.COMPANY,
  },
  {
    label: 'Partnership',
    value: BusinessStructureExtended.PARTNERSHIP,
  },
  {
    label: 'Sole trader',
    value: BusinessStructureExtended.SOLE_TRADER,
  },
  {
    label: 'Trust with company trustee',
    value: BusinessStructureExtended.TRUST,
  },
  {
    label: 'Trust with individual trustees',
    value: BusinessStructureExtended.TRUST_INDIVIDUAL,
  },
  {
    label: "I'm not sure",
    value: BusinessStructureExtended.UNKNOWN,
  },
];

export const contractRoleMap = {
  [ContractRole.BUILDER]: 'Builder / Head contractor',
  [ContractRole.CONTRACTOR]: 'Contractor',
  [ContractRole.SUBCONTRACTOR]: 'Subcontractor',
  [ContractRole.SUPPLIER]: 'Supplier',
  [ContractRole.PRINCIPAL]: 'Property owner / Principal',
  [ContractRole.SUPERINTENDENT]: 'Superintendent',
  [ContractRole.QUANTITY_SURVEYOR]: 'Quantity surveyor',
  [ContractRole.ARCHITECT]: 'Architect',
};

export const securityTypeOptions = {
  [SecurityType.FIXED_AMOUNT]: 'Fixed amount',
  [SecurityType.PERCENTAGE]: 'Percentage',
};

export const projectAndContractRoleMap = {
  [ProjectRole.PRINCIPAL]: ContractRole.PRINCIPAL,
  [ProjectRole.BUILDER]: ContractRole.BUILDER,
  [ProjectRole.SUBCONTRACTOR]: ContractRole.SUBCONTRACTOR,
  [ProjectRole.SUPPLIER]: ContractRole.SUPPLIER,
  [ProjectRole.SUPERINTENDENT]: ContractRole.ARCHITECT,
};

export const whoCanReviewContractRoleMap = {
  [PayType.PAYER]: ContractRole.PRINCIPAL,
  [PayType.PAYEE]: ContractRole.PRINCIPAL,
  [PayType.INTERMEDIARY]: ContractRole.ARCHITECT,
};

export const definitionsIdMap = {
  [SupercontractBrand.ABIC]: 'c_S1',
  [SupercontractBrand.MBA]: 'c_1-1-1',
  [SupercontractBrand.PAID]: 'c_1-1',
  [SupercontractBrand.RBN]: '',
  [SupercontractBrand.AIA]: '',
};

export const inlineBlockTags = ['#text', 'em', 'a', 'span'] as const;
export const blockTags = ['div', 'p', 'ol', 'dl'] as const;

export * from './icon';
export * from './party';
export * from './payment-stage';
export * from './product';
export * from './signatory';
export * from './state';
