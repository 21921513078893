export const JPGIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6417 0.607056C14.2442 0.607056 14.7326 1.09547 14.7326 1.69796V8.24342H21.2781C21.8805 8.24342 22.369 8.73184 22.369 9.33433C22.369 9.93682 21.8805 10.4252 21.2781 10.4252H13.6417C13.0392 10.4252 12.5508 9.93682 12.5508 9.33433V1.69796C12.5508 1.09547 13.0392 0.607056 13.6417 0.607056Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0071 0.607056C5.13912 0.607056 4.30669 0.95186 3.69293 1.56562C3.07918 2.17937 2.73438 3.0118 2.73438 3.87978V10.8343H4.91619V3.87978C4.91619 3.59046 5.03113 3.31298 5.23571 3.10839C5.4403 2.90381 5.71778 2.78887 6.0071 2.78887H13.1916L20.1889 9.7862V21.3343C20.1889 21.6237 20.074 21.9011 19.8694 22.1057C19.6648 22.3103 19.3873 22.4252 19.098 22.4252H6.0071C5.71778 22.4252 5.4403 22.3103 5.23571 22.1057C5.03113 21.9011 4.91619 21.6237 4.91619 21.3343V21.198H2.73438V21.3343C2.73438 22.2023 3.07918 23.0347 3.69293 23.6485C4.30669 24.2623 5.13912 24.6071 6.0071 24.6071H19.098C19.966 24.6071 20.7984 24.2623 21.4122 23.6485C22.0259 23.0347 22.3707 22.2023 22.3707 21.3343V9.33433C22.3707 9.045 22.2558 8.76752 22.0512 8.56294L14.4149 0.926576C14.2103 0.72199 13.9328 0.607056 13.6435 0.607056H6.0071Z"
      />
      <path d="M4.65144 19.64C4.17363 19.64 3.75472 19.5517 3.39472 19.375C3.03472 19.1917 2.75653 18.9364 2.56017 18.6091C2.36381 18.2819 2.26562 17.9022 2.26562 17.4702V16.8124C2.26562 16.7797 2.27544 16.7535 2.29508 16.7339C2.32126 16.7077 2.35072 16.6946 2.38344 16.6946H3.28672C3.31944 16.6946 3.34563 16.7077 3.36526 16.7339C3.39144 16.7535 3.40453 16.7797 3.40453 16.8124V17.4702C3.40453 17.8237 3.51581 18.1117 3.73835 18.3342C3.96744 18.5502 4.27181 18.6582 4.65144 18.6582C5.01799 18.6582 5.31908 18.5502 5.55472 18.3342C5.79035 18.1117 5.90817 17.8237 5.90817 17.4702V12.8066C5.90817 12.7739 5.91799 12.7477 5.93762 12.728C5.96381 12.7019 5.99326 12.6888 6.02599 12.6888H6.91944C6.95217 12.6888 6.97835 12.7019 6.99799 12.728C7.02417 12.7477 7.03726 12.7739 7.03726 12.8066V17.4702C7.03726 17.9022 6.93581 18.2819 6.7329 18.6091C6.53653 18.9364 6.25508 19.1917 5.88853 19.375C5.52853 19.5517 5.11617 19.64 4.65144 19.64Z" />
      <path d="M10.7481 12.679C11.1605 12.679 11.5238 12.764 11.8379 12.9342C12.1521 13.1044 12.3943 13.3466 12.5645 13.6608C12.7412 13.9684 12.8296 14.3251 12.8296 14.731C12.8296 15.1302 12.7412 15.4837 12.5645 15.7913C12.3878 16.099 12.1358 16.3379 11.8085 16.508C11.4878 16.6717 11.1147 16.7535 10.6892 16.7535H9.1183C9.08557 16.7535 9.06921 16.7699 9.06921 16.8026V19.4437C9.06921 19.4764 9.05612 19.5059 9.02993 19.532C9.0103 19.5517 8.98412 19.5615 8.95139 19.5615H8.04812C8.01539 19.5615 7.98593 19.5517 7.95975 19.532C7.94012 19.5059 7.9303 19.4764 7.9303 19.4437V12.7968C7.9303 12.764 7.94012 12.7379 7.95975 12.7182C7.98593 12.692 8.01539 12.679 8.04812 12.679H10.7481ZM10.5812 15.8306C10.915 15.8306 11.1834 15.7324 11.3863 15.536C11.5892 15.3397 11.6907 15.0811 11.6907 14.7604C11.6907 14.4331 11.5892 14.168 11.3863 13.9651C11.1834 13.7622 10.915 13.6608 10.5812 13.6608H9.1183C9.08557 13.6608 9.06921 13.6771 9.06921 13.7099V15.7815C9.06921 15.8142 9.08557 15.8306 9.1183 15.8306H10.5812Z" />
      <path d="M16.0187 19.64C15.5278 19.64 15.0958 19.5451 14.7227 19.3553C14.3497 19.159 14.0617 18.884 13.8587 18.5306C13.6558 18.1771 13.5544 17.7713 13.5544 17.3131V14.9371C13.5544 14.4724 13.6558 14.0666 13.8587 13.7197C14.0617 13.3662 14.3497 13.0946 14.7227 12.9048C15.0958 12.7084 15.5278 12.6102 16.0187 12.6102C16.5031 12.6102 16.9286 12.7051 17.2951 12.895C17.6682 13.0848 17.9562 13.3433 18.1591 13.6706C18.362 13.9979 18.4635 14.3677 18.4635 14.78V14.8586C18.4635 14.8913 18.4504 14.9208 18.4242 14.947C18.4046 14.9666 18.3784 14.9764 18.3457 14.9764H17.4522C17.4195 14.9764 17.39 14.9666 17.3638 14.947C17.3442 14.9208 17.3344 14.8913 17.3344 14.8586V14.8095C17.3344 14.456 17.2133 14.1648 16.9711 13.9357C16.7355 13.7066 16.418 13.592 16.0187 13.592C15.6195 13.592 15.2987 13.7131 15.0566 13.9553C14.8144 14.191 14.6933 14.5051 14.6933 14.8979V17.3524C14.6933 17.7451 14.8209 18.0626 15.0762 18.3048C15.3315 18.5404 15.662 18.6582 16.0678 18.6582C16.4606 18.6582 16.7682 18.5568 16.9907 18.3539C17.2198 18.1444 17.3344 17.8597 17.3344 17.4997V16.891C17.3344 16.8582 17.318 16.8419 17.2853 16.8419H16.0777C16.0449 16.8419 16.0155 16.832 15.9893 16.8124C15.9697 16.7862 15.9598 16.7568 15.9598 16.724V15.9975C15.9598 15.9648 15.9697 15.9386 15.9893 15.919C16.0155 15.8928 16.0449 15.8797 16.0777 15.8797H18.3457C18.3784 15.8797 18.4046 15.8928 18.4242 15.919C18.4504 15.9386 18.4635 15.9648 18.4635 15.9975V17.2542C18.4635 18.007 18.2409 18.5928 17.7958 19.0117C17.3573 19.4306 16.7649 19.64 16.0187 19.64Z" />
    </svg>
  );
};
