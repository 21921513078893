import { type RetainableInfo } from '@paid-ui/types/retentions';

import { contractManager } from '../model';
import { type Contract } from '../types';

export const saveRetentions = (contract?: Contract) => {
  if (!contract) return;

  const { retentions, retentionSchedule } = contract;

  contractManager.retentions = retentions ?? [];
  contractManager.retentionSchedule = retentionSchedule ?? null;
};

export const saveRetainableInfo = (data: RetainableInfo) => {
  contractManager.retainableInfo = data;
};
