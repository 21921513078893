import { ClaimStatus } from '@paid-ui/enums/claim';
import { ContractState, ContractTemplate, ContractType } from '@paid-ui/enums/contract';

import { contractManager } from '../model';
import { type Contract } from '../types';

const ongoingStatusSet = new Set([
  ContractState.SUBMITTED,
  ContractState.RESUBMITTED,
  ContractState.REJECTED,
]);

const contractStatusTextMap: Record<ContractState, string> = {
  [ContractState.EXECUTED]: 'Executed',
  [ContractState.ALL_PAID]: 'All claims paid',
  [ContractState.ABANDONED]: 'Abandoned',
  [ContractState.TERMINATED]: 'Terminated',
  [ContractState.SUBMITTED]: 'Submitted',
  [ContractState.RESUBMITTED]: 'Resubmitted',
  [ContractState.ACCEPTED]: 'Accepted',
  [ContractState.REJECTED]: 'Amendments requested',
};

export const saveStatuses = (contract?: Contract) => {
  if (!contract) return;
  const {
    contractType,
    contractTemplate,
    isDraftContract,
    contractState,
    progressPayments,
    paymentSchedule,
  } = contract;
  const { numberOfParticipants, numberOfJoined, numberOfApproved, numberOfSigned } =
    contractManager;
  const isPeriodic = Boolean(paymentSchedule);

  const isOneLastPaymentLeft =
    progressPayments.filter(({ claim }) => {
      return (
        !claim || (claim.state && ![ClaimStatus.APPROVED, ClaimStatus.PAID].includes(claim.state))
      );
    }).length === 1;

  const isContractAccepted = contractState === ContractState.ACCEPTED;
  const isContractExecuted = contractState === ContractState.EXECUTED;
  const isContractClosed = [
    ContractState.ABANDONED,
    ContractState.ALL_PAID,
    ContractState.TERMINATED,
  ].includes(contractState);

  let contractStateText = 'Loading...';
  if (contract.isDraftContract) {
    contractStateText = 'Not yet shared';
  } else if (
    contract.contractState === ContractState.SUBMITTED &&
    numberOfJoined < numberOfParticipants
  ) {
    contractStateText = 'Parties not yet all joined';
  } else if (ongoingStatusSet.has(contractState) && numberOfApproved < 2) {
    contractStateText = 'Not yet all approved';
  } else if (contractState === ContractState.ACCEPTED && numberOfSigned < numberOfParticipants) {
    contractStateText = 'Not yet all signed';
  } else {
    contractStateText = contractStatusTextMap[contract.contractState] ?? 'Unknown';
  }

  contractManager.contractStateText = contractStateText;
  contractManager.statuses = {
    isPeriodic,
    isDraftContract: Boolean(isDraftContract),
    isContractAccepted,
    isContractClosed,
    isContractExecuted,
    isOffPlatformContract: contractTemplate?.startsWith(ContractTemplate.OFF_PLATFORM) ?? false,
    isOneLastPaymentLeft,
    isPrimeContract: contractType === ContractType.HEAD_CONTRACT,
    isRBNContract: contractTemplate?.startsWith(ContractTemplate.RBN) ?? false,
    isSuperContract: contractTemplate === ContractTemplate.SUPER_CONTRACT,
  };
};
