export const ArchitectIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08579 2.58579C3.60606 2.06551 4.34167 1.8918 5.00573 2.06467L33.9693 9.05588C34.7264 9.23861 35.3085 9.84416 35.4612 10.6078L37.7673 22.1385C38.4854 21.8563 39.3338 22.0054 39.9142 22.5858L45.9142 28.5858C46.6953 29.3668 46.6953 30.6332 45.9142 31.4142L31.9142 45.4142C31.1332 46.1953 29.8668 46.1953 29.0858 45.4142L23.0858 39.4142C22.5054 38.8338 22.3563 37.9854 22.6385 37.2673L11.1078 34.9612C10.3442 34.8085 9.73861 34.2264 9.55588 33.4693L2.56467 4.50573C2.3918 3.84167 2.56551 3.10606 3.08579 2.58579ZM17.0847 19.4132L8.11207 10.4405L13.1446 31.2893L25.8426 33.8289L34.3289 25.3426L31.7893 12.6446L10.9405 7.61207L19.9132 16.5847C20.6964 16.2099 21.5737 16 22.5 16C25.8137 16 28.5 18.6863 28.5 22C28.5 25.3137 25.8137 28 22.5 28C19.1863 28 16.5 25.3137 16.5 22C16.5 21.0737 16.7099 20.1964 17.0847 19.4132ZM21.0612 20.6108C20.7138 20.9706 20.5 21.4603 20.5 22C20.5 23.1046 21.3954 24 22.5 24C23.6046 24 24.5 23.1046 24.5 22C24.5 20.8954 23.6046 20 22.5 20C21.9603 20 21.4706 20.2138 21.1108 20.5612C21.1027 20.5696 21.0945 20.5779 21.0862 20.5862C21.0779 20.5945 21.0696 20.6027 21.0612 20.6108ZM27.9033 37.4251L27.9143 37.4143L37.9143 27.4143L37.9251 27.4033L38.5 26.8284L41.6716 30L30.5 41.1716L27.3284 38L27.9033 37.4251Z"
      />
    </svg>
  );
};
