export enum ApprovalStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum SnapshotType {
  ACCEPT_CONTRACT = 'ACCEPT_CONTRACT',
  REJECT_CONTRACT = 'REJECT_CONTRACT',
  CONTRACT = 'CONTRACT',
  EARLY_RELEASE_REQUEST = 'EARLY_RELEASE_REQUEST',
}
