import { AddressState } from '@paid-ui/enums/address';

export const states = {
  [AddressState.VIC]: 'Victoria',
  [AddressState.NSW]: 'New South Wales',
  [AddressState.WA]: 'Western Australia',
  [AddressState.SA]: 'South Australia',
  [AddressState.NT]: 'Northern Territory',
  [AddressState.ACT]: 'Australian Capital Territory',
  [AddressState.QLD]: 'Queensland',
  [AddressState.TAS]: 'Tasmania',
};
