import { ContractTemplate, ContractType } from '@paid-ui/enums/contract';
import { RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';
import { projectManager } from '@paid-ui/models/project';

import { initialState } from '../initialState';
import { contractManager } from '../model';
import { resetContract } from './resetContract';

export const setContractId = (contractId: string) => {
  const { id } = contractManager;
  if (!id) {
    resetContract();
    return;
  }
  if (id === contractId) return;

  const { defaultContractId, contractSummaries, requiringActions } = projectManager;
  const targetContract = contractSummaries.find(
    (contract) => contract.id === contractId || contract.id === defaultContractId,
  );

  if (targetContract) {
    Object.assign(contractManager, initialState, {
      ...targetContract,
      requiringActions: requiringActions.filter(
        (action) =>
          action.data?.contractId === contractId &&
          action.featureItemType === RequiringActionFeatureType.CONTRACT,
      ),
      statuses: {
        isDraftContract: !!targetContract.isDraftContract,
        isPrimeContract: targetContract.contractType === ContractType.HEAD_CONTRACT,
        isRBNContract: targetContract.contractTemplate?.startsWith(ContractTemplate.RBN),
        isOffPlatformContract: targetContract.contractTemplate?.startsWith(
          ContractTemplate.OFF_PLATFORM,
        ),
      },
    });
  }
};
