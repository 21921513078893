export enum WorkItemStatus {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  // new added values for variation only
  RESUBMITTED = 'RESUBMITTED',
  REJECTED = 'REJECTED',
  ABANDONED = 'ABANDONED',
}

export enum WorkItemPaymentStatus {
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  ADJUSTED = 'ADJUSTED',
}

export enum WorkItemType {
  LINE = 'LINE',
  VARIATION = 'VARIATION',
}
