import { ContractInsuranceType } from '@paid-ui/constants';
import { ContractType } from '@paid-ui/enums/contract';

import { contractManager } from '../model';
import { type Contract } from '../types';

export const saveInsurances = (contract: Contract) => {
  if (!contract || contract.contractType !== ContractType.HEAD_CONTRACT) return;

  const requirements = contract.requirements ?? [];
  const insurances = contract.insurances ?? [];
  contractManager.requirements = requirements;
  contractManager.insurances = insurances;
  contractManager.isDepositInsuranceRequired = requirements.some(
    (requirement) => requirement.type === 'DEPOSIT_INSURANCE',
  );

  const depositInsuranceCertificate =
    insurances.find(
      (insurance) => insurance.type === ContractInsuranceType.DEPOSIT_BUILDING_INSURANCE,
    )?.attachment ?? null;
  contractManager.depositInsuranceCertificate = depositInsuranceCertificate;
  contractManager.isDepositInsuranceExists = Boolean(depositInsuranceCertificate);

  const contractInsuranceCertificate =
    insurances.find(
      (insurance) => insurance.type === ContractInsuranceType.CONTRACT_BUILDING_INSURANCE,
    )?.attachment ?? null;
  contractManager.contractInsuranceCertificate = contractInsuranceCertificate;
  contractManager.isContractInsuranceExists = Boolean(contractInsuranceCertificate);
};
