/**
 * Get next code from current code
 *
 * @param code - Current code
 * @returns Next code
 */
export function getNextCode(code: string = '0') {
  let nextCode = '1';
  const reInteger = /(?<code>[1-9]\d*)$/;

  if (reInteger.test(code)) {
    const codeStr = code.match(reInteger)?.groups?.code;
    const numberInCodeStr = Number(codeStr) + 1;
    nextCode = code.replace(reInteger, numberInCodeStr.toString());
  }

  return nextCode;
}

/**
 * Format display name with feature item code and name
 *
 * @param code - Feature item code
 * @param name - Feature item name
 * @returns Formatted display name
 */
export function formatDisplayName(code?: string, name?: string) {
  return [code, name].filter(Boolean).join(' · ');
}
