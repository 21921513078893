export const PDFIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6222 0.607056C14.2246 0.607056 14.7131 1.09547 14.7131 1.69796V8.2434H21.2585C21.861 8.2434 22.3494 8.73182 22.3494 9.33431C22.3494 9.9368 21.861 10.4252 21.2585 10.4252H13.6222C13.0197 10.4252 12.5312 9.9368 12.5312 9.33431V1.69796C12.5312 1.09547 13.0197 0.607056 13.6222 0.607056Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98756 0.607056C5.11958 0.607056 4.28716 0.951859 3.6734 1.56561C3.05965 2.17937 2.71484 3.0118 2.71484 3.87978V10.8344H4.89666V3.87978C4.89666 3.59045 5.01159 3.31297 5.21618 3.10839C5.42076 2.9038 5.69824 2.78887 5.98756 2.78887H13.172L20.1693 9.78618V21.3343C20.1693 21.6236 20.0544 21.9011 19.8498 22.1057C19.6452 22.3103 19.3678 22.4252 19.0784 22.4252H5.98756C5.69824 22.4252 5.42076 22.3103 5.21618 22.1057C5.01159 21.9011 4.89666 21.6236 4.89666 21.3343V21.198H2.71484V21.3343C2.71484 22.2023 3.05965 23.0347 3.6734 23.6484C4.28715 24.2622 5.11958 24.607 5.98756 24.607H19.0784C19.9464 24.607 20.7788 24.2622 21.3926 23.6484C22.0064 23.0347 22.3512 22.2023 22.3512 21.3343V9.33431C22.3512 9.04498 22.2362 8.76751 22.0316 8.56292L14.3953 0.926575C14.1907 0.72199 13.9132 0.607056 13.6239 0.607056H5.98756Z"
      />
      <path d="M5.57172 12.6788C5.98408 12.6788 6.34735 12.7639 6.66153 12.9341C6.97571 13.1043 7.2179 13.3465 7.38808 13.6606C7.5648 13.9683 7.65317 14.325 7.65317 14.7308C7.65317 15.1301 7.5648 15.4836 7.38808 15.7912C7.21135 16.0988 6.95935 16.3377 6.63208 16.5079C6.31135 16.6716 5.93826 16.7534 5.51281 16.7534H3.9419C3.90918 16.7534 3.89281 16.7697 3.89281 16.8025V19.4435C3.89281 19.4763 3.87972 19.5057 3.85354 19.5319C3.8339 19.5515 3.80772 19.5614 3.77499 19.5614H2.87172C2.839 19.5614 2.80954 19.5515 2.78336 19.5319C2.76372 19.5057 2.75391 19.4763 2.75391 19.4435V12.7967C2.75391 12.7639 2.76372 12.7377 2.78336 12.7181C2.80954 12.6919 2.839 12.6788 2.87172 12.6788H5.57172ZM5.40481 15.8305C5.73863 15.8305 6.00699 15.7323 6.2099 15.5359C6.41281 15.3396 6.51426 15.081 6.51426 14.7603C6.51426 14.433 6.41281 14.1679 6.2099 13.965C6.00699 13.7621 5.73863 13.6606 5.40481 13.6606H3.9419C3.90918 13.6606 3.89281 13.677 3.89281 13.7097V15.7814C3.89281 15.8141 3.90918 15.8305 3.9419 15.8305H5.40481Z" />
      <path d="M8.57748 19.5614C8.54475 19.5614 8.5153 19.5515 8.48912 19.5319C8.46948 19.5057 8.45966 19.4763 8.45966 19.4435V12.8065C8.45966 12.7737 8.46948 12.7476 8.48912 12.7279C8.5153 12.7017 8.54475 12.6887 8.57748 12.6887H10.9731C11.4378 12.6887 11.8469 12.7705 12.2004 12.9341C12.5538 13.0977 12.8255 13.3334 13.0153 13.641C13.2117 13.9421 13.3098 14.2956 13.3098 14.7014V17.5486C13.3098 17.9545 13.2117 18.3112 13.0153 18.6188C12.8255 18.9199 12.5538 19.1523 12.2004 19.3159C11.8469 19.4795 11.4378 19.5614 10.9731 19.5614H8.57748ZM9.59857 18.5305C9.59857 18.5632 9.61493 18.5795 9.64766 18.5795H11.0222C11.3691 18.5795 11.644 18.4715 11.8469 18.2555C12.0564 18.033 12.1644 17.7385 12.1709 17.3719V14.8879C12.1709 14.5148 12.0662 14.2203 11.8567 14.0043C11.6473 13.7817 11.3658 13.6705 11.0124 13.6705H9.64766C9.61493 13.6705 9.59857 13.6868 9.59857 13.7196V18.5305Z" />
      <path d="M18.7761 13.5526C18.7761 13.5854 18.763 13.6148 18.7368 13.641C18.7172 13.6606 18.691 13.6705 18.6582 13.6705H15.3201C15.2873 13.6705 15.271 13.6868 15.271 13.7196V15.5556C15.271 15.5883 15.2873 15.6046 15.3201 15.6046H17.5684C17.6012 15.6046 17.6273 15.6177 17.647 15.6439C17.6732 15.6636 17.6863 15.6897 17.6863 15.7225V16.4686C17.6863 16.5014 17.6732 16.5308 17.647 16.557C17.6273 16.5766 17.6012 16.5865 17.5684 16.5865H15.3201C15.2873 16.5865 15.271 16.6028 15.271 16.6356V19.4435C15.271 19.4763 15.2579 19.5057 15.2317 19.5319C15.2121 19.5515 15.1859 19.5614 15.1532 19.5614H14.2499C14.2172 19.5614 14.1877 19.5515 14.1615 19.5319C14.1419 19.5057 14.1321 19.4763 14.1321 19.4435V12.8065C14.1321 12.7737 14.1419 12.7476 14.1615 12.7279C14.1877 12.7017 14.2172 12.6887 14.2499 12.6887H18.6582C18.691 12.6887 18.7172 12.7017 18.7368 12.7279C18.763 12.7476 18.7761 12.7737 18.7761 12.8065V13.5526Z" />
    </svg>
  );
};
