export const Building2Icon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <g clipPath="url(#clip0_2_4473)">
        <path d="M10.5 9.75H12V11.25H10.5V9.75ZM13.5 11.25H15V9.75H13.5V11.25ZM10.5 14.25H12V12.75H10.5V14.25ZM13.5 14.25H15V12.75H13.5V14.25ZM10.5 5.25H12V3.75H10.5V5.25ZM13.5 5.25H15V3.75H13.5V5.25ZM10.5 8.25H12V6.75H10.5V8.25ZM13.5 8.25H15V6.75H13.5V8.25ZM18 2.25V18H0V6.62175C0 6.021 0.234 5.4555 0.65925 5.03025L2.6895 3C3.65625 2.0325 5.34375 2.0325 6.3105 3L7.5 4.1895V2.25C7.5 1.0095 8.5095 0 9.75 0H15.75C16.9905 0 18 1.0095 18 2.25ZM7.5 6.62175C7.5 6.4215 7.422 6.2325 7.28025 6.09075L5.25 4.0605C4.8555 3.666 4.1445 3.666 3.75 4.0605L1.71975 6.09075C1.578 6.2325 1.5 6.42075 1.5 6.62175V16.5H7.5V6.62175ZM16.5 2.25C16.5 1.83675 16.1632 1.5 15.75 1.5H9.75C9.33675 1.5 9 1.83675 9 2.25V16.5H16.5V2.25ZM3.75 11.25H5.25V9.75H3.75V11.25ZM3.75 8.25H5.25V6.75H3.75V8.25ZM3.75 14.25H5.25V12.75H3.75V14.25Z" />
      </g>
      <defs>
        <clipPath id="clip0_2_4473">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
