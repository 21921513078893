export const Staged3Icon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <g clipPath="url(#clip0_7831_14123)">
        <path
          d="M4.5 1.5H1.5C1.08579 1.5 0.75 1.83579 0.75 2.25V3.75C0.75 4.16421 1.08579 4.5 1.5 4.5H4.5C4.91421 4.5 5.25 4.16421 5.25 3.75V2.25C5.25 1.83579 4.91421 1.5 4.5 1.5Z"
          fill="#F2F2F2"
        />
        <path
          d="M9 7.5H6C5.58579 7.5 5.25 7.83579 5.25 8.25V9.75C5.25 10.1642 5.58579 10.5 6 10.5H9C9.41421 10.5 9.75 10.1642 9.75 9.75V8.25C9.75 7.83579 9.41421 7.5 9 7.5Z"
          fill="#F2F2F2"
        />
        <path
          d="M16.5 13.5H10.5C10.0858 13.5 9.75 13.8358 9.75 14.25V15.75C9.75 16.1642 10.0858 16.5 10.5 16.5H16.5C16.9142 16.5 17.25 16.1642 17.25 15.75V14.25C17.25 13.8358 16.9142 13.5 16.5 13.5Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_7831_14123">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
