export enum PartyType {
  BUSINESS = 'BUSINESS',
  INDIVIDUALS = 'INDIVIDUALS',
}

export enum BusinessStructureExtended {
  COMPANY = 'COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
  TRUST_INDIVIDUAL = 'TRUST_INDIVIDUAL',
  UNKNOWN = 'UNKNOWN',
}

export enum PartyMismatchType {
  PARTY_TYPE = 'PARTY_TYPE',
  BUSINESS_INFO = 'BUSINESS_INFO',
  NUMBER_OF_USERS = 'NUMBER_OF_USERS',
  UNKNOWN = 'UNKNOWN',
}

export enum PaymentAuthorizedStatus {
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  DECLINED = 'DECLINED',
  ABANDONED = 'ABANDONED',
}
