import { PayType } from '@paid-ui/enums/party';

export const signatoryPayTypeMap = {
  [PayType.PAYEE]: PayType.PAYEE,
  [PayType.PAYER]: PayType.PAYER,
  [PayType.INTERMEDIARY]: PayType.PAYER,
};

export const theOtherSignatoryPayTypeMap = {
  [PayType.PAYEE]: PayType.PAYER,
  [PayType.PAYER]: PayType.PAYEE,
  [PayType.INTERMEDIARY]: PayType.PAYEE,
};
