export const Calendar3Icon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      {...props}
    >
      <g clipPath="url(#clip0_7829_13239)">
        <path d="M13.75 3H1.25V6.75H13.75V3Z" fill="#F2F2F2" />
        <path d="M12.5 3H2.5C1.80964 3 1.25 3.55964 1.25 4.25V13C1.25 13.6904 1.80964 14.25 2.5 14.25H12.5C13.1904 14.25 13.75 13.6904 13.75 13V4.25C13.75 3.55964 13.1904 3 12.5 3Z" />
        <path d="M4.375 1.75V4.25" />
        <path d="M10.625 1.75V4.25" />
        <path d="M1.25 6.75H13.75" />
        <path d="M10.625 11.125C10.9702 11.125 11.25 10.8452 11.25 10.5C11.25 10.1548 10.9702 9.875 10.625 9.875C10.2798 9.875 10 10.1548 10 10.5C10 10.8452 10.2798 11.125 10.625 11.125Z" />
      </g>
      <defs>
        <clipPath id="clip0_7829_13239">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
