export const PNGIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6222 0.607056C14.2247 0.607056 14.7131 1.09547 14.7131 1.69796V8.24342H21.2585C21.861 8.24342 22.3494 8.73184 22.3494 9.33433C22.3494 9.93682 21.861 10.4252 21.2585 10.4252H13.6222C13.0197 10.4252 12.5312 9.93682 12.5312 9.33433V1.69796C12.5312 1.09547 13.0197 0.607056 13.6222 0.607056Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98757 0.607056C5.11959 0.607056 4.28716 0.95186 3.6734 1.56562C3.05965 2.17937 2.71484 3.0118 2.71484 3.87978V10.8343H4.89666V3.87978C4.89666 3.59046 5.0116 3.31298 5.21618 3.10839C5.42077 2.90381 5.69824 2.78887 5.98757 2.78887H13.1721L20.1694 9.7862V21.3343C20.1694 21.6237 20.0545 21.9011 19.8499 22.1057C19.6453 22.3103 19.3678 22.4252 19.0785 22.4252H5.98757C5.69824 22.4252 5.42077 22.3103 5.21618 22.1057C5.0116 21.9011 4.89666 21.6237 4.89666 21.3343V21.198H2.71484V21.3343C2.71484 22.2023 3.05965 23.0347 3.6734 23.6485C4.28716 24.2623 5.11959 24.6071 5.98757 24.6071H19.0785C19.9465 24.6071 20.7789 24.2623 21.3926 23.6485C22.0064 23.0347 22.3512 22.2023 22.3512 21.3343V9.33433C22.3512 9.045 22.2363 8.76752 22.0317 8.56294L14.3953 0.926576C14.1907 0.72199 13.9133 0.607056 13.6239 0.607056H5.98757Z"
      />
      <path d="M5.62251 12.679C6.03487 12.679 6.39814 12.764 6.71232 12.9342C7.02651 13.1044 7.26869 13.3466 7.43887 13.6608C7.6156 13.9684 7.70396 14.3251 7.70396 14.731C7.70396 15.1302 7.6156 15.4837 7.43887 15.7913C7.26214 16.099 7.01014 16.3379 6.68287 16.508C6.36214 16.6717 5.98905 16.7535 5.5636 16.7535H3.99269C3.95996 16.7535 3.9436 16.7699 3.9436 16.8026V19.4437C3.9436 19.4764 3.93051 19.5059 3.90432 19.532C3.88469 19.5517 3.85851 19.5615 3.82578 19.5615H2.92251C2.88978 19.5615 2.86032 19.5517 2.83414 19.532C2.81451 19.5059 2.80469 19.4764 2.80469 19.4437V12.7968C2.80469 12.764 2.81451 12.7379 2.83414 12.7182C2.86032 12.692 2.88978 12.679 2.92251 12.679H5.62251ZM5.4556 15.8306C5.78941 15.8306 6.05778 15.7324 6.26069 15.536C6.4636 15.3397 6.56505 15.0811 6.56505 14.7604C6.56505 14.4331 6.4636 14.168 6.26069 13.9651C6.05778 13.7622 5.78941 13.6608 5.4556 13.6608H3.99269C3.95996 13.6608 3.9436 13.6771 3.9436 13.7099V15.7815C3.9436 15.8142 3.95996 15.8306 3.99269 15.8306H5.4556Z" />
      <path d="M12.4042 12.8066C12.4042 12.7739 12.414 12.7477 12.4336 12.728C12.4598 12.7019 12.4893 12.6888 12.522 12.6888H13.4253C13.458 12.6888 13.4842 12.7019 13.5038 12.728C13.53 12.7477 13.5431 12.7739 13.5431 12.8066V19.4437C13.5431 19.4764 13.53 19.5059 13.5038 19.532C13.4842 19.5517 13.458 19.5615 13.4253 19.5615H12.5515C12.486 19.5615 12.4402 19.5353 12.414 19.483L9.58637 14.8488C9.57328 14.8291 9.56018 14.8226 9.54709 14.8291C9.534 14.8291 9.52746 14.8422 9.52746 14.8684L9.54709 19.4437C9.54709 19.4764 9.534 19.5059 9.50782 19.532C9.48818 19.5517 9.462 19.5615 9.42928 19.5615H8.526C8.49327 19.5615 8.46382 19.5517 8.43764 19.532C8.418 19.5059 8.40818 19.4764 8.40818 19.4437V12.8066C8.40818 12.7739 8.418 12.7477 8.43764 12.728C8.46382 12.7019 8.49327 12.6888 8.526 12.6888H9.39982C9.46527 12.6888 9.51109 12.715 9.53727 12.7673L12.3551 17.4015C12.3682 17.4211 12.3813 17.431 12.3944 17.431C12.4075 17.4244 12.414 17.408 12.414 17.3819L12.4042 12.8066Z" />
      <path d="M16.8255 19.64C16.3346 19.64 15.9026 19.5451 15.5295 19.3553C15.1564 19.159 14.8684 18.884 14.6655 18.5306C14.4626 18.1771 14.3611 17.7713 14.3611 17.3131V14.9371C14.3611 14.4724 14.4626 14.0666 14.6655 13.7197C14.8684 13.3662 15.1564 13.0946 15.5295 12.9048C15.9026 12.7084 16.3346 12.6102 16.8255 12.6102C17.3099 12.6102 17.7353 12.7051 18.1019 12.895C18.475 13.0848 18.763 13.3433 18.9659 13.6706C19.1688 13.9979 19.2702 14.3677 19.2702 14.78V14.8586C19.2702 14.8913 19.2571 14.9208 19.231 14.947C19.2113 14.9666 19.1851 14.9764 19.1524 14.9764H18.259C18.2262 14.9764 18.1968 14.9666 18.1706 14.947C18.151 14.9208 18.1411 14.8913 18.1411 14.8586V14.8095C18.1411 14.456 18.0201 14.1648 17.7779 13.9357C17.5422 13.7066 17.2248 13.592 16.8255 13.592C16.4262 13.592 16.1055 13.7131 15.8633 13.9553C15.6211 14.191 15.5001 14.5051 15.5001 14.8979V17.3524C15.5001 17.7451 15.6277 18.0626 15.883 18.3048C16.1382 18.5404 16.4688 18.6582 16.8746 18.6582C17.2673 18.6582 17.575 18.5568 17.7975 18.3539C18.0266 18.1444 18.1411 17.8597 18.1411 17.4997V16.891C18.1411 16.8582 18.1248 16.8419 18.0921 16.8419H16.8844C16.8517 16.8419 16.8222 16.832 16.7961 16.8124C16.7764 16.7862 16.7666 16.7568 16.7666 16.724V15.9975C16.7666 15.9648 16.7764 15.9386 16.7961 15.919C16.8222 15.8928 16.8517 15.8797 16.8844 15.8797H19.1524C19.1851 15.8797 19.2113 15.8928 19.231 15.919C19.2571 15.9386 19.2702 15.9648 19.2702 15.9975V17.2542C19.2702 18.007 19.0477 18.5928 18.6026 19.0117C18.1641 19.4306 17.5717 19.64 16.8255 19.64Z" />
    </svg>
  );
};
