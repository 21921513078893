export const BrushIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="currentColor"
      {...props}
    >
      <g clipPath="url(#clip0_2165_115702)">
        <path d="M15 2.25C15 1.0095 13.9905 0 12.75 0H2.25C1.0095 0 0 1.0095 0 2.25V3.75C0 4.9905 1.0095 6 2.25 6H12.75C13.9905 6 15 4.9905 15 3.75C15.8273 3.75 16.5 4.42275 16.5 5.25V6C16.5 6.82725 15.8273 7.5 15 7.5H9.75C8.0955 7.5 6.75 8.8455 6.75 10.5V10.638C5.87925 10.9485 5.25 11.7735 5.25 12.75V15.75C5.25 16.9905 6.2595 18 7.5 18C8.7405 18 9.75 16.9905 9.75 15.75V12.75C9.75 11.7735 9.12075 10.9485 8.25 10.638V10.5C8.25 9.67275 8.92275 9 9.75 9H15C16.6545 9 18 7.6545 18 6V5.25C18 3.5955 16.6545 2.25 15 2.25ZM13.5 3.75C13.5 4.164 13.1632 4.5 12.75 4.5H2.25C1.83675 4.5 1.5 4.164 1.5 3.75V2.25C1.5 1.836 1.83675 1.5 2.25 1.5H12.75C13.1632 1.5 13.5 1.836 13.5 2.25V3.75ZM8.25 15.75C8.25 16.164 7.91325 16.5 7.5 16.5C7.08675 16.5 6.75 16.164 6.75 15.75V12.75C6.75 12.336 7.08675 12 7.5 12C7.91325 12 8.25 12.336 8.25 12.75V15.75Z" />
      </g>
      <defs>
        <clipPath id="clip0_2165_115702">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
