import { PaymentStageName } from '@paid-ui/enums/payment';

import { type StandardPayment } from '../types';

export const standardPayments = [
  {
    stage: PaymentStageName.DEPOSIT,
    percentOfContractSum: 5,
  },
  {
    stage: PaymentStageName.BASE,
    percentOfContractSum: 10,
  },
  {
    stage: PaymentStageName.FRAME,
    percentOfContractSum: 15,
  },
  {
    stage: PaymentStageName.LOCK_UP,
    percentOfContractSum: 35,
  },
  {
    stage: PaymentStageName.FIXING,
    percentOfContractSum: 25,
  },
  {
    stage: PaymentStageName.PRACTICAL_COMPLETION,
    percentOfContractSum: 10,
  },
];

export const fixingStandardPayments = [
  {
    stage: PaymentStageName.DEPOSIT,
    percentOfContractSum: 5,
  },
  {
    stage: PaymentStageName.BASE,
    percentOfContractSum: 12,
  },
  {
    stage: PaymentStageName.FRAME,
    percentOfContractSum: 18,
  },
  {
    stage: PaymentStageName.LOCK_UP,
    percentOfContractSum: 40,
  },
  {
    stage: PaymentStageName.FIXING,
    percentOfContractSum: 25,
  },
];

export const lockUpStandardPayments = [
  {
    stage: PaymentStageName.DEPOSIT,
    percentOfContractSum: 5,
  },
  {
    stage: PaymentStageName.BASE,
    percentOfContractSum: 20,
  },
  {
    stage: PaymentStageName.FRAME,
    percentOfContractSum: 25,
  },
  {
    stage: PaymentStageName.LOCK_UP,
    percentOfContractSum: 50,
  },
];

export const standardPaymentsMap = {
  [PaymentStageName.PRACTICAL_COMPLETION]: standardPayments,
  [PaymentStageName.FIXING]: fixingStandardPayments,
  [PaymentStageName.LOCK_UP]: lockUpStandardPayments,
} as Record<PaymentStageName, StandardPayment[]>;

export const paymentStageMap: Record<PaymentStageName, string> = {
  [PaymentStageName.DEPOSIT]: 'Deposit',
  [PaymentStageName.BASE]: 'Base',
  [PaymentStageName.FRAME]: 'Frame',
  [PaymentStageName.LOCK_UP]: 'Lock-up',
  [PaymentStageName.FIXING]: 'Fixing',
  [PaymentStageName.PRACTICAL_COMPLETION]: 'Final',
  [PaymentStageName.OTHER]: '',
  [PaymentStageName.UNKNOWN]: 'Unknown',
};

export const paymentCustomNameMap: Record<string, PaymentStageName> = {
  'Deposit': PaymentStageName.DEPOSIT,
  'Base': PaymentStageName.BASE,
  'Frame': PaymentStageName.FRAME,
  'Lock-up': PaymentStageName.LOCK_UP,
  'Fixing': PaymentStageName.FIXING,
  'Final': PaymentStageName.PRACTICAL_COMPLETION,
};
