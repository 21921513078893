import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { userManager } from './user';

const initialState = {
  accountId: '',
  statuses: {
    isAccountReadyForParty: false,
    isAccountAbleToUse: false,
  },
};

export const projectUserGroupManager = proxy(initialState);

devtools(projectUserGroupManager, {
  name: 'Current User Group',
  enabled: false,
});

export const resetProjectUserGroup = () => {
  Object.assign(projectUserGroupManager, initialState);
};

export const saveProjectUserGroupAccount = (accountId = '') => {
  projectUserGroupManager.accountId = accountId;
  const { accountSet } = userManager;
  projectUserGroupManager.statuses.isAccountReadyForParty = Boolean(accountId);
  projectUserGroupManager.statuses.isAccountAbleToUse =
    Boolean(accountId) && accountSet.has(accountId);
};
