export enum ProductReferenceCode {
  ONPLATFORM_PRIME_RBN_NEW_HOME = 'ONPLATFORM_PRIME_RBN_NEW_HOME',
  ONPLATFORM_PRIME_RBN_IMPROVEMENT = 'ONPLATFORM_PRIME_RBN_IMPROVEMENT',
  ONPLATFORM_SUB_PAID = 'ONPLATFORM_SUB_PAID',
  ONPLATFORM_SUPPLY_PAID = 'ONPLATFORM_SUPPLY_PAID',
  MBA_DECON_HEAD_2024 = 'MBA_DECON_HEAD_2024',
  MBA_DECON_SUB_2024 = 'MBA_DECON_SUB_2024',
  ABIC_HEAD_BW_2018 = 'ABIC_HEAD_BW_2018',
  ABIC_HEAD_CP_2014 = 'ABIC_HEAD_CP_2014',
  ABIC_HEAD_MW_2018 = 'ABIC_HEAD_MW_2018',
  ABIC_HEAD_SW_2018 = 'ABIC_HEAD_SW_2018',
  ABIC_SUB_MW_2018 = 'ABIC_SUB_MW_2018',
  ABIC_SUB_SW_2018 = 'ABIC_SUB_SW_2018',
  ABIC_SUB_BWC_2018 = 'ABIC_SUB_BWC_2018',
  PAID_NHR_HEAD_NSW_2024 = 'PAID_NHR_HEAD_NSW_2024',
  PAID_NHR_HEAD_QLD_2024 = 'PAID_NHR_HEAD_QLD_2024',
  PAID_NHR_HEAD_SA_2024 = 'PAID_NHR_HEAD_SA_2024',
  PAID_NHR_HEAD_TAS_2024 = 'PAID_NHR_HEAD_TAS_2024',
  PAID_NHR_HEAD_VIC_2024 = 'PAID_NHR_HEAD_VIC_2024',
  PAID_NHR_HEAD_WA_2024 = 'PAID_NHR_HEAD_WA_2024',
}

export enum ProductType {
  CONTRACT = 'CONTRACT',
}
