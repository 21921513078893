import { proxyMap } from 'valtio/utils';

export const preSignedUrlManager = proxyMap<string, string>();

export const clearPreSignedUrls = () => {
  preSignedUrlManager.clear();
};

export const savePreSignedUrls = (preSignedUrls: Record<string, string>) => {
  Object.keys(preSignedUrls).forEach((key) => {
    const url = preSignedUrls[key];
    if (url && !preSignedUrlManager.has(key)) {
      preSignedUrlManager.set(key, url);
    }
  });
};

export const savePreSignedUrl = (key: string, value: string) => {
  if (!preSignedUrlManager.has(key)) {
    preSignedUrlManager.set(key, value);
  }
};

export const removePreSignedUrl = (key: string) => {
  if (preSignedUrlManager.has(key)) {
    preSignedUrlManager.delete(key);
  }
};
