import { ClaimStatus } from '@paid-ui/enums/claim';
import { paymentManager, setCurrentPayment } from '@paid-ui/models/payment';
import { type PaymentStage } from '@paid-ui/types/payments';

import { contractManager } from '../model';
import { formatProgressPayment } from './formatProgressPayment';
import { saveProvisionalClaims } from './saveProvisionalClaims';

export const savePeriodicClaims = (payments: PaymentStage[] = []) => {
  const provisionalClaims = payments.filter(
    (payment) => payment.claim?.state === ClaimStatus.PROVISIONAL,
  );
  saveProvisionalClaims(provisionalClaims);

  const restClaims = payments.filter((payment) => payment.claim?.state !== ClaimStatus.PROVISIONAL);
  contractManager.totalNumberOfPayments = restClaims.length;
  contractManager.numberOfClaimedPayments = restClaims.length;
  contractManager.numberOfUnclaimedPayments = 0;
  contractManager.payments = restClaims.map((payment, index) =>
    formatProgressPayment(payment, { index }),
  );

  const { id } = paymentManager;
  if (id) {
    setCurrentPayment(id);
  }
};
