import { RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';
import { projectManager } from '@paid-ui/models/project';

import { contractManager } from '../model';

export const saveRequiringActions = (contractId: string) => {
  const { requiringActions } = projectManager;
  contractManager.requiringActions = requiringActions.filter(
    (action) =>
      action.data?.contractId === contractId &&
      action.featureItemType === RequiringActionFeatureType.CONTRACT,
  );
};
