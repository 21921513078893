import { ClaimMethod, PropertyUsage } from '@paid-ui/enums/claim';
import { ContractRole, ContractType } from '@paid-ui/enums/contract';
import { BrushIcon } from '@paid-ui/icons/brush';
import { Building2Icon } from '@paid-ui/icons/building-2';
import { Calendar3Icon } from '@paid-ui/icons/calendar-3';
import { DeliveryTruckIcon } from '@paid-ui/icons/delivery-truck';
import { HelmetIcon } from '@paid-ui/icons/helmet';
import { Home2Icon } from '@paid-ui/icons/home-2';
import { JPGIcon } from '@paid-ui/icons/jpg';
import { OwnerKeyIcon } from '@paid-ui/icons/owner-key';
import { PDFIcon } from '@paid-ui/icons/pdf';
import { PNGIcon } from '@paid-ui/icons/png';
import { ArchitectIcon } from '@paid-ui/icons/sketch';
import { Staged3Icon } from '@paid-ui/icons/staged-3';
import { VideoIcon } from '@paid-ui/icons/video';

export const contractTypeIcon = {
  [ContractType.HEAD_CONTRACT]: HelmetIcon,
  [ContractType.SUBCONTRACT]: BrushIcon,
  [ContractType.SUPPLY_CONTRACT]: DeliveryTruckIcon,
};

export const claimMethodIcon = {
  [ClaimMethod.STAGED]: Staged3Icon,
  [ClaimMethod.PERIODIC]: Calendar3Icon,
};

export const propertyUsageIcon = {
  [PropertyUsage.COMMERCIAL]: Building2Icon,
  [PropertyUsage.RESIDENTIAL]: Home2Icon,
};

export const contractRoleIconMap = {
  [ContractRole.PRINCIPAL]: OwnerKeyIcon,
  [ContractRole.CONTRACTOR]: HelmetIcon,
  [ContractRole.BUILDER]: HelmetIcon,
  [ContractRole.SUBCONTRACTOR]: BrushIcon,
  [ContractRole.SUPPLIER]: DeliveryTruckIcon,
  [ContractRole.ARCHITECT]: ArchitectIcon,
  [ContractRole.SUPERINTENDENT]: ArchitectIcon,
  [ContractRole.QUANTITY_SURVEYOR]: ArchitectIcon,
};

export const fileIconMap = {
  'application/pdf': PDFIcon,
  'image/jpeg': JPGIcon,
  'image/png': PNGIcon,
  'video/mp4': VideoIcon,
  'video/quicktime': VideoIcon,
} as Record<string, React.ElementType>;
