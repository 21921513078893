export const DeliveryTruckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width="1em"
      height="1em"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path d="M0.75 1.5C0.335786 1.5 0 1.83579 0 2.25V12C0 12.4142 0.335786 12.75 0.75 12.75H1.75261C1.59063 13.091 1.5 13.4724 1.5 13.875C1.5 15.3247 2.67525 16.5 4.125 16.5C5.57475 16.5 6.75 15.3247 6.75 13.875C6.75 13.4724 6.65937 13.091 6.49739 12.75H11.5026C11.3406 13.091 11.25 13.4724 11.25 13.875C11.25 15.3247 12.4253 16.5 13.875 16.5C15.3247 16.5 16.5 15.3247 16.5 13.875C16.5 13.4724 16.4094 13.091 16.2474 12.75H17.25C17.6642 12.75 18 12.4142 18 12V8.25C18 8.05109 17.921 7.86032 17.7803 7.71967L15.5303 5.46967C15.3897 5.32902 15.1989 5.25 15 5.25H12.75V2.25C12.75 1.83579 12.4142 1.5 12 1.5H0.75ZM13.875 11.25H16.5V8.56066L14.6893 6.75H12.75V11.25H13.875ZM13.875 12.75C13.2537 12.75 12.75 13.2537 12.75 13.875C12.75 14.4963 13.2537 15 13.875 15C14.4963 15 15 14.4963 15 13.875C15 13.2537 14.4963 12.75 13.875 12.75ZM3 13.875C3 13.2537 3.50368 12.75 4.125 12.75C4.74632 12.75 5.25 13.2537 5.25 13.875C5.25 14.4963 4.74632 15 4.125 15C3.50368 15 3 14.4963 3 13.875ZM11.25 11.25H4.125H1.5V3H11.25V6V11.25Z" />
    </svg>
  );
};
