import { ContractAction, RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';
import { WorkItemStatus } from '@paid-ui/enums/work-item';
import { userManager } from '@paid-ui/models/user';
import { type RequiringAction } from '@paid-ui/types';
import { formatDisplayName } from '@paid-ui/utils/fid';
import { sumBy } from 'lodash';

import { contractManager } from '../model';
import { type Contract } from '../types';

const notApprovedSet = new Set([
  WorkItemStatus.SUBMITTED,
  WorkItemStatus.RESUBMITTED,
  WorkItemStatus.REJECTED,
]);

export const variationRequiringActionFeatureTypeSet = new Set([
  RequiringActionFeatureType.VARIATION,
]);

export const saveWorkItems = (contract: Contract, actions: RequiringAction[] = []) => {
  if (!contract) return;
  const { profile } = userManager;
  const { lineWorkItems = [], variationWorkItems = [], participantParties } = contract;

  const currentParty = participantParties?.find(
    (party) => party.userGroup?.id === profile?.groupRelation?.group.id,
  );

  const isInviter = currentParty?.role?.actions.includes(ContractAction.VARIATION_SUBMIT) ?? false;
  const isInvitee = !isInviter;

  const formattedLineWorkItems = lineWorkItems.map((item) => ({
    ...item,
    displayName: formatDisplayName(item.code, item.name),
  }));

  const formattedVariationWorkItems = variationWorkItems
    .map((item) => ({
      ...item,
      displayName: formatDisplayName(item.code, item.name),
      statuses: {
        isInviter,
        isInvitee,
      },
      permissions: {
        canAbandon:
          isInviter && notApprovedSet.has(item.workItemStatus) && !item?.currentAssessedAmount,
        canSecure: false,
      },
      requiringActions: actions.filter(
        ({ featureItemId, featureItemType }) =>
          variationRequiringActionFeatureTypeSet.has(featureItemType) && item.id === featureItemId,
      ),
    }))
    .reverse();

  const workItems = [...formattedLineWorkItems, ...formattedVariationWorkItems];

  contractManager.numberOfWorkItems = workItems.length;
  contractManager.numberOfLineWorkItems = lineWorkItems.length;
  contractManager.numberOfVariationWorkItems = variationWorkItems.length;

  contractManager.lineWorkItems = formattedLineWorkItems;
  contractManager.variationWorkItems = formattedVariationWorkItems;
  contractManager.workItems = workItems;

  const lineWorkItemsAmount = sumBy(lineWorkItems, (item) => Number(item.amount));
  const variationWorkItemsAmount = sumBy(variationWorkItems, (item) => Number(item.amount));
  const workItemsAmount = sumBy(workItems, (item) => Number(item.amount));
  const lineWorkItemsAssessedAmount = sumBy(lineWorkItems, (item) =>
    Number(item.currentAssessedAmount),
  );
  const variationWorkItemsAssessedAmount = sumBy(variationWorkItems, (item) =>
    Number(item.currentAssessedAmount),
  );

  contractManager.lineWorkItemsAmount = lineWorkItemsAmount;
  contractManager.variationWorkItemsAmount = variationWorkItemsAmount;
  contractManager.workItemsAmount = workItemsAmount;
  contractManager.lineWorkItemsAssessedAmount = lineWorkItemsAssessedAmount;
  contractManager.variationWorkItemsAssessedAmount = variationWorkItemsAssessedAmount;
  contractManager.totalAssessedAmount = contract.totalAssessedAmount ?? 0;
};
