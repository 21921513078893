import { PaymentStageName } from '@paid-ui/enums/payment';
import { paymentManager, setCurrentPayment } from '@paid-ui/models/payment';
import { type PaymentStage } from '@paid-ui/types/payments';
import { groupBy } from 'lodash';

import { contractManager } from '../model';
import { formatProgressPayment } from './formatProgressPayment';

export const saveProgressPayments = (payments: PaymentStage[] = []) => {
  const { deposits = [], restPayments = [] } = groupBy(payments, (payment) =>
    payment.stage === PaymentStageName.DEPOSIT ? 'deposits' : 'restPayments',
  );

  const [deposit, ...restDeposits] = deposits;
  const allPayments = deposit ? [deposit, ...restDeposits, ...restPayments] : payments;
  const claimedPayments = allPayments.filter((payment) => Boolean(payment.claim?.id));

  contractManager.totalNumberOfPayments = allPayments.length;
  contractManager.numberOfClaimedPayments = claimedPayments.length;
  contractManager.numberOfUnclaimedPayments = allPayments.length - claimedPayments.length;
  contractManager.payments = allPayments.map((payment, index) =>
    formatProgressPayment(payment, { index }),
  );

  const { id } = paymentManager;
  if (id) {
    setCurrentPayment(id);
  }
};
