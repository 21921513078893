import { type ContractRole } from '@paid-ui/enums/contract';
import { ContractAction } from '@paid-ui/enums/requiring-action';
import { type ContractResponsibility } from '@paid-ui/types/contracts';

import { contractManager } from '../model';

export const saveRolesAndResponsibilities = (
  roles?: Partial<Record<ContractRole, ContractResponsibility>>,
) => {
  const getResponsibilities = (action: ContractAction) => {
    return Object.entries(roles ?? {})
      .filter(([role, { actions }]) => actions.includes(action))
      .map(([role]) => role);
  };

  contractManager.roles = roles ?? {};
  contractManager.responsibilities = Object.fromEntries(
    [
      ContractAction.CONTRACT_REVIEW,
      ContractAction.CONTRACT_AMEND,
      ContractAction.CLAIM_SUBMIT,
      ContractAction.CLAIM_REVIEW,
      ContractAction.CLAIM_ASSESSED,
      ContractAction.CLAIM_PAY,
      ContractAction.VARIATION_SUBMIT,
      ContractAction.VARIATION_REVIEW,
    ].map((action) => [action, getResponsibilities(action)]),
  ) as Record<ContractAction, ContractRole[]>;
};
