export const OwnerKeyIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9148 2.58579C44.6958 3.36683 44.6958 4.63317 43.9148 5.41421L41.329 8L45.9148 12.5858C46.6958 13.3668 46.6958 14.6332 45.9148 15.4142L38.9148 22.4142C38.1337 23.1953 36.8674 23.1953 36.0863 22.4142L31.5005 17.8284L26.0077 23.3213C26.6226 24.1529 27.1375 25.0569 27.5399 26.0149C28.204 27.5956 28.5488 29.2921 28.5546 31.0066C28.5603 32.7211 28.2269 34.4198 27.5734 36.0049C26.92 37.59 25.9594 39.0302 24.7471 40.2425C23.5347 41.4548 22.0945 42.4154 20.5094 43.0689C18.9243 43.7223 17.2256 44.0558 15.5111 44.05C13.7966 44.0443 12.1002 43.6995 10.5195 43.0354C8.9388 42.3713 7.50509 41.4011 6.30089 40.1807L6.28589 40.1655C3.91784 37.7137 2.60759 34.4298 2.63721 31.0212C2.66683 27.6127 4.03403 24.3521 6.44433 21.9418C8.85464 19.5315 12.1152 18.1643 15.5238 18.1347C18.2694 18.1108 20.9341 18.9564 23.1482 20.5239L41.0863 2.58579C41.8674 1.80474 43.1337 1.80474 43.9148 2.58579ZM21.9443 24.7119C21.9256 24.6952 21.9073 24.6781 21.8891 24.6606C20.1917 23.0212 17.9183 22.114 15.5585 22.1345C13.1987 22.155 10.9414 23.1015 9.27276 24.7702C7.60408 26.4389 6.65757 28.6962 6.63706 31.056C6.61658 33.4122 7.52098 35.6823 9.15574 37.3789C9.98786 38.2203 10.9777 38.8893 12.0688 39.3476C13.1631 39.8074 14.3375 40.0461 15.5245 40.0501C16.7115 40.054 17.8875 39.8232 18.9849 39.3708C20.0823 38.9184 21.0793 38.2534 21.9186 37.4141C22.7579 36.5748 23.4229 35.5777 23.8753 34.4803C24.3277 33.383 24.5586 32.2069 24.5546 31.02C24.5506 29.833 24.3119 28.6585 23.8522 27.5642C23.4049 26.4996 22.7571 25.5313 21.9443 24.7119ZM34.329 15L37.5005 18.1716L41.6721 14L38.5005 10.8284L34.329 15Z"
      />
    </svg>
  );
};
