export const VideoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <g clipPath="url(#clip0_2807_32773)">
        <path d="M23.5 7.35986L16.5 12.3599L23.5 17.3599V7.35986Z" />
        <path d="M14.5 5.35986H3.5C2.39543 5.35986 1.5 6.25529 1.5 7.35986V17.3599C1.5 18.4644 2.39543 19.3599 3.5 19.3599H14.5C15.6046 19.3599 16.5 18.4644 16.5 17.3599V7.35986C16.5 6.25529 15.6046 5.35986 14.5 5.35986Z" />
      </g>
      <defs>
        <clipPath id="clip0_2807_32773">
          <rect width="24" height="24" fill="none" transform="translate(0.5 0.359863)" />
        </clipPath>
      </defs>
    </svg>
  );
};
