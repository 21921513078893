import { ClaimStatus } from '@paid-ui/enums/claim';
import { ContractState } from '@paid-ui/enums/contract';
import { PartyLinkingStatus } from '@paid-ui/enums/party';
import { userManager } from '@paid-ui/models/user';
import dayjs from 'dayjs';

import { contractManager } from '../model';

export const savePermissions = () => {
  const { impersonation } = userManager;
  const {
    contractState,
    hasAllSigned,
    commencementDate,
    actualCompletionDate,
    payments,
    currentParty,
    lastReviewParty,
    isPayee,
    isInvitee,
    inviteeParty,
    isInviter,
    statuses: {
      isPrimeContract,
      isOffPlatformContract,
      isContractExecuted,
      isDraftContract,
      isRBNContract,
      isSuperContract,
    },
  } = contractManager;

  const isContractSubmitted = contractState === ContractState.SUBMITTED && !isDraftContract;

  const isCommencementDateBeforeToday =
    !!commencementDate && dayjs(commencementDate).isBefore(dayjs().startOf('day'));

  const hasPaymentUnclaimed = payments?.some((payment) => !payment.claim);

  const hasPaymentUnclaimedOrRejected = payments.some(
    (payment) => !payment.claim || payment.claim?.state === ClaimStatus.REJECTED,
  );

  const canAbandonContract =
    isInviter &&
    !isPrimeContract &&
    !isDraftContract &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED, ContractState.REJECTED].includes(
      contractState,
    );

  const canCreateClaim =
    isPayee && !isDraftContract && isContractExecuted && hasAllSigned && hasPaymentUnclaimed;

  const canClaimRetention = false;

  const canCreateVariation =
    ((isPrimeContract && isPayee) || !isPrimeContract) &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned &&
    hasPaymentUnclaimedOrRejected;

  const canResendInvitation = isPrimeContract
    ? isContractSubmitted &&
      isInviter &&
      inviteeParty?.linkingStatus === PartyLinkingStatus.UNLINKED
    : isContractSubmitted && isInviter && inviteeParty?.linkingStatus === PartyLinkingStatus.LINKED;

  const canReviewOnPlatformContract = !isOffPlatformContract && !!currentParty && isInvitee;

  const canReviewOffPlatformContract =
    isOffPlatformContract && !!currentParty && currentParty.id !== lastReviewParty?.id;

  const canReviewContract =
    (canReviewOnPlatformContract || canReviewOffPlatformContract) &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED].includes(contractState);

  const canSendCommencementNotice =
    !isDraftContract && isPayee && isContractExecuted && !commencementDate;

  const canSendCompletionNotice =
    !isDraftContract &&
    isPayee &&
    isContractExecuted &&
    !actualCompletionDate &&
    isCommencementDateBeforeToday;

  const canSignContract =
    isPrimeContract &&
    !isOffPlatformContract &&
    contractState === ContractState.ACCEPTED &&
    currentParty?.linkingStatus !== PartyLinkingStatus.SIGNED;

  const canViewContract = !isDraftContract;

  const canRevokeRBNContract =
    impersonation && isRBNContract && contractState === ContractState.ACCEPTED && !hasAllSigned;

  const canRevokeSuperContract =
    isSuperContract && contractState === ContractState.ACCEPTED && !hasAllSigned;

  const canRevokeContract = canRevokeRBNContract || canRevokeSuperContract;

  contractManager.permissions = {
    canAbandonContract,
    canCreateClaim,
    canCreateVariation,
    canResendInvitation,
    canReviewContract,
    canSendCommencementNotice,
    canSendCompletionNotice,
    canSignContract,
    canViewContract,
    canClaimRetention,
    canRevokeContract,
  };
};
